import * as yup from "yup";
import { useEffect, useMemo } from "react";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Stack from "@mui/material/Stack";
import CheckIcon from "@mui/icons-material/Check";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";
import { TextField } from "@mui/material";

import { usePopover } from "src/hooks/use-popover";
import Iconify from "../iconify";


export const PageNumberSelect = ({currentPage, totalPage, onUpdate }) => {
  const popover = usePopover();

  const validation = useMemo(()=> {
    const result = yup.object({
      pageNumber: yup.number().min(1).max(totalPage).integer(),
    });
    return result;
  }, [totalPage]);
  
  const { register, control, setValue, formState: { isValid } } = useForm({ resolver: yupResolver(validation)});

  const pageNumber = useWatch({ control, name: 'pageNumber' });

  useEffect(() => {
    setValue('pageNumber', (currentPage + 1));
  }, [popover.open]);
  
  return (
    <>
      <Stack
        sx={{ px: 0, py: 0, display: "flex", justifyContent: "start", alignItems: 'center' }}
      >
        <Stack sx={{ flexDirection: 'row', gap: 2, alignItems: 'center' }}>
          <Typography
            sx={{ fontSize: 14, display: 'flex', justifyContent: 'flex-end' }}
          >
            Page number:
          </Typography>
          <Stack
            onClick={popover.handleOpen}
            ref={popover.anchorRef}
            sx={{ flexDirection: 'row', alignItems: 'center', gap: 0.5, cursor: 'pointer' }}
          >
            <Typography
              sx={{ fontSize: 14, display: 'flex', justifyContent: 'flex-end' }}
            >
              {currentPage >=0 ? (currentPage + 1) : 0}
            </Typography>
            <Iconify icon="fe:drop-down" sx={{ color: 'text.disabled'}} width={16} />
          </Stack>
        </Stack>
      </Stack>
      <Menu
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        open={popover.open}
        PaperProps={{ 
          style: { 
            px: 10, 
            transform: 'translate(40px, -18px)' 
          } 
        }}
      >
        <Stack sx={{ px: 2, py: 1 }} direction="row">
          <TextField
            size="small"
            type="number"
            hiddenLabel
            {...register('pageNumber')}
            sx={{ width: 100, mr: 1 }}
          />
          <IconButton
            disabled={!isValid}
            color="success"
            onClick={() => onUpdate((parseInt(pageNumber)-1))}
          >
            <CheckIcon fontSize="small" />
          </IconButton>
        </Stack>
      </Menu>
    </>
  );
};
